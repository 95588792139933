import React from 'react'
import { Link } from 'react-router-dom'
import image from '../assets/img/new/single-bottles3.png'

function Countdown() {
  return (
   <div className="ltn__call-to-action-area ltn__call-to-action-4 section-bg-1 pt-110 pb-120">
  <div className="container">
    <div className="row">
      <div className="col-lg-5">
        <img src={image} alt="#" />
      </div>
      <div className="col-lg-7">
        <div className="call-to-action-inner call-to-action-inner-4 text-color-white--- text-center---">
          <div className="section-title-area ltn__section-title-2 text-center---">
            <h6 className="ltn__secondary-color">Todays Hot Deals</h6>
            <h1 className="section-title">Original Honey <br />  BooBee Classic</h1>
          </div>
          <div className="btn-wrapper animated">
            <Link to="/honey" className="theme-btn-1 btn btn-effect-1 text-uppercase">Buy now</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Countdown