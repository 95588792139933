import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MobileMenu from '../components/MobileMenu'

function WebsiteLayout({children, theme}) {
  return (
    <div className="body-wrapper">
        <Header/>
        <MobileMenu/>
         <div className="ltn__utilize-overlay"></div>
        {children}
        <Footer/>
    </div>
  )
}

export default WebsiteLayout