import React from 'react'
import Countdown from '../components/Countdown'
import Feature from '../components/Feature'
import Homebanner from '../components/Homebanner'
import Jumbotron from '../components/Jumbotron'
import Producttab from '../components/Producttab'
import WebsiteLayout from '../layouts/WebsiteLayout'

function Home() {
  return (
    <WebsiteLayout>
        <Jumbotron/>
        <Homebanner/>
        <Producttab/>
        <Countdown/>
        <Feature/>

    </WebsiteLayout>
  )
}

export default Home