import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/img/new/logo.png';

function Footer() {
  return (
    <footer className="ltn__footer-area  ">
  <div className="footer-top-area  section-bg-1 plr--5">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
          <div className="footer-widget footer-about-widget">
            <div className="footer-logo">
              <div className="site-logo">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            <p>Bogaty Farms is a purpose-driven corporation geared towards the curbing of health complications and casualties.</p>
            <div className="footer-address">
              <ul>
                <li>
                  <div className="footer-address-icon">
                    <i className="icon-placeholder" />
                  </div>
                  <div className="footer-address-info">
                    <p>No 1 Nung Udo Akpan Street, Ibesikpo Asutan LGA, Akwa Ibom State, Nigeria.</p>
                  </div>
                </li>
                <li>
                  <div className="footer-address-icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="footer-address-info">
                    <p><a href="tel:2348029197523">+234 (0)802 919 7523</a></p>
                  </div>
                </li>
                <li>
                  <div className="footer-address-icon">
                    <i className="icon-mail" />
                  </div>
                  <div className="footer-address-info">
                    <p><a href="mailto:info@bogatyfarms.com">info@bogatyfarms.com</a></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="ltn__social-media mt-20">
              <ul>
              <li><a href="https://www.facebook.com/bogatyfarms" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="https://twitter.com/bogatyfarms" title="Twitter"><i className="fab fa-twitter" /></a></li>
                      <li><a href="https://instagram.com/bogatyfarms" title="Instagram"><i className="fab fa-instagram" /></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
          <div className="footer-widget footer-menu-widget clearfix">
            <h4 className="footer-title">Quick Links</h4>
            <div className="footer-menu">
              <ul>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li><Link to="/shop">Shop</Link></li>
                <li><Link to="/contact">Contact us</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
          <div className="footer-widget footer-menu-widget clearfix">
            <h4 className="footer-title">Account.</h4>
            <div className="footer-menu">
              <ul>
                <li><Link to="/login">Login</Link></li>
                <li><Link to="/register">Register</Link></li>
                <li><Link to="/order">Orders</Link></li>
                <li><Link to="/account">My account</Link></li>
              </ul>
            </div>
          </div>
        </div>
      
        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
          <div className="footer-widget footer-newsletter-widget">
            <h4 className="footer-title">Newsletter</h4>
            <p>Subscribe to our weekly Newsletter and receive updates via email.</p>
            <div className="footer-newsletter">
              <form action="#">
                <input type="email" name="email" placeholder="Email*" />
                <div className="btn-wrapper">
                  <button className="theme-btn-1 btn" type="submit"><i className="fas fa-location-arrow" /></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="ltn__copyright-area ltn__copyright-2 section-bg-2  ltn__border-top-2--- plr--5">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="ltn__copyright-design clearfix">
            <p>All Rights Reserved @Bogatyfarms <span className="current-year" /></p>
          </div>
        </div>
        <div className="col-md-6 col-12 align-self-center">
          <div className="ltn__copyright-menu text-right">
            <ul>
              <li><a href="#">Terms &amp; Conditions</a></li>
              <li><a href="#">Privacy &amp; Policy</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

  )
}

export default Footer