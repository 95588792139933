import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/img/new/logo.png';

function Header() {
  return (
<header className="ltn__header-area ltn__header-5 ltn__header-transparent gradient-color-4---">
  {/* ltn__header-top-area start */}
  <div className="ltn__header-top-area">
    <div className="container">
      <div className="row">
        <div className="col-md-7">
          <div className="ltn__top-bar-menu">
            <ul>
              <li><Link to="/"><i className="icon-placeholder" /> No 1 Nung Udo Akpan Street, Ibesikpo Asutan LGA, Akwa Ibom State, Nigeria.</Link></li>
              <li><a href="mailto:info@bogatyfarms.com"><i className="icon-mail" /> info@bogatyfarms.com</a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-5">
          <div className="top-bar-right text-right">
            <div className="ltn__top-bar-menu">
              <ul>
                <li>
                  {/* ltn__language-menu */}
                  <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                    <ul>
                      <li><a href="https://wa.me/2348029197523" className=""><span className="active-currency">
                          <i className='icon-whatsapp' style={{color:"darkgreen"}}></i>
                          +234 (0)802 919 7523</span></a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  {/* ltn__social-media */}
                  <div className="ltn__social-media">
                    <ul>
                      <li><a href="https://www.facebook.com/bogatyfarms" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="https://twitter.com/bogatyfarms" title="Twitter"><i className="fab fa-twitter" /></a></li>
                      <li><a href="https://instagram.com/bogatyfarms" title="Instagram"><i className="fab fa-instagram" /></a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* ltn__header-top-area end */}
  {/* ltn__header-middle-area start */}
  <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white plr--9---">
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="site-logo-wrap">
            <div className="site-logo">
              <Link to="/"><img src={logo} alt="Logo" style={{width:150}} /></Link>
            </div>
          </div>
        </div>
        <div className="col header-menu-column menu-color-white---">
          <div className="header-menu d-none d-xl-block">
            <nav>
              <div className="ltn__main-menu">
                <ul>
                  <li className=""><Link to="/">Home</Link></li>
                  <li className=""><Link to="/about">About</Link> </li>
                  <li className=""><Link to="/services">Services</Link></li>
                  <li className=""><Link to="/shop">Shop</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                  <li className="special-link"><a href="https://paystack.com/buy/PROD_c3fswgg9rw1z6vc" target="_blank">BUY NOW</a></li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="ltn__header-options ltn__header-options-2">
          <div className="header-search-wrap">
            <div className="header-search-1">
              <div className="search-icon">
                <i className="icon-search for-search-show" />
                <i className="icon-cancel  for-search-close" />
              </div>
            </div>
            <div className="header-search-1-form">
              <form id="#" method="get" action="#">
                <input type="text" name="search" defaultValue placeholder="Search here..." />
                <button type="submit">
                  <span><i className="icon-search" /></span>
                </button>
              </form>
            </div>
          </div>
          <div className="ltn__drop-menu user-menu">
            <ul>
              <li>
                <a href="#"><i className="icon-user" /></a>
                <ul>
                  <li><Link to="/login">Sign in</Link></li>
                  <li><Link to="/register">Register</Link></li>
                  <li><Link to="/account">My Account</Link></li>
                  <li><Link to="/wishlist">Wishlist</Link></li>
                </ul>
              </li>
            </ul>
          </div>
          {/* mini-cart */}
          <div className="mini-cart-icon">
            <a href="#ltn__utilize-cart-menu" className="ltn__utilize-toggle">
              <i className="icon-shopping-cart" />
              <sup>0</sup>
            </a>
          </div>
          {/* mini-cart */}
          {/* Mobile Menu Button */}
          <div className="mobile-menu-toggle d-xl-none">
            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
              <svg viewBox="0 0 800 600">
                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
                <path d="M300,320 L540,320" id="middle" />
                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* ltn__header-middle-area end */}
</header>

  )
}

export default Header