import React from 'react'

function Feature() {
  return (
   <div className="ltn__feature-area before-bg-bottom-2 mb--30--- plr--5">
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-12">
        <div className="ltn__feature-item-box-wrap ltn__border-between-column white-bg">
          <div className="row">
            <div className="col-xl-4 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-8">
                <div className="ltn__feature-icon">
                  <img src={require("../assets/img/icons/icon-img/11.png")} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Curated Products</h4>
                  <p>We provide Curated Products for
                    all our products</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-8">
                <div className="ltn__feature-icon">
                  <img src={require("../assets/img/icons/icon-img/12.png")} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Handmade</h4>
                  <p>We ensure that product quality
                     is our main goal</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-8">
                <div className="ltn__feature-icon">
                  <img src={require("../assets/img/icons/icon-img/13.png")} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h4>Natural Food</h4>
                  <p>Our porducts are sourced naturally to enusre the very best quality</p>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Feature