import React from 'react'
import { Link } from 'react-router-dom'

import slider1 from "../assets/img/new/5-bottles.png"
import imgA from "../assets/img/icons/icon-img/1.png"
import slider2 from "../assets/img/new/single-bottles3.png"

function Jumbotron() {
  return (
  <div className="ltn__slider-area ltn__slider-3  section-bg-1">
  <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3">
      <div className="ltn__slide-item-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <div className="slide-item-info">
                <div className="slide-item-info-inner ltn__slide-animation">
                  <div className="slide-video mb-50 d-none">
                    <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="" data-rel="lightcase:myCollection">
                      <i className="fa fa-play" />
                    </a>
                  </div>
                  <h6 className="slide-sub-title animated"><img src={imgA} alt="#" /> 100% genuine Products</h6>
                  <h1 className="slide-title animated ">Tasty &amp; Healthy <br />  Organic Food</h1>
                  <div className="slide-brief animated d-none">
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p> */}
                  </div>
                  <div className="btn-wrapper animated">
                    <Link to="/honey" className="theme-btn-1 btn btn-effect-1 text-uppercase">Explore Products</Link>
                  </div>
                </div>
              </div>
              <div className="slide-item-img">
                <img src={slider1} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* ltn__slide-item */}
    <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3">
      <div className="ltn__slide-item-inner  text-right">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 align-self-center">
              <div className="slide-item-info">
                <div className="slide-item-info-inner ltn__slide-animation">
                  <h6 className="slide-sub-title animated"><img src={imgA} alt="#" /> 100% genuine Products</h6>
                  <h1 className="slide-title animated ">Our Garden's  Most <br />   Favorite Food</h1>
                  <div className="slide-brief animated">
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p> */}
                  </div>
                  <div className="btn-wrapper animated">
                    <Link to="/shop" className="theme-btn-1 btn btn-effect-1 text-uppercase">Explore Products</Link>
                  </div>
                </div>
              </div>
              <div className="slide-item-img slide-img-left">
                <img src={slider2} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Jumbotron