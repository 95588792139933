import React from 'react'
import HoneyJumbo from '../components/HoneyJumbo'
import HoneyPageDetails from '../components/HoneyPageDetails'
import WebsiteLayout from '../layouts/WebsiteLayout'

function Honey() {
  return (
    <WebsiteLayout >
        <HoneyJumbo/>
        <HoneyPageDetails/>
    </WebsiteLayout>
  )
}

export default Honey