import React from 'react'
import banner3 from '../assets/img/new/banner3.png';
import bblogo from '../assets/img/new/bblogo.png';

function HoneyJumbo() {
  return (
    <div className="ltn__breadcrumb-area ltn__breadcrumb-area-3 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image" 
    // data-bg={require("../assets/img/bg/9.jpg")}
    data-bg={banner3}
    >
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
          <div className="section-title-area ltn__section-title-2">
            <img src={bblogo} style={{width:250, marginLeft:120}} />
            {/* <h6 className="section-subtitle ltn__secondary-color">experience buzzing goodness</h6> */}
            {/* <h1 className="section-title white-color" style={{color:"#4b2500", webkitTextStroke: "1px #fff"}}>BooBee Honey Classic</h1> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default HoneyJumbo