import React from 'react'
import { Link } from 'react-router-dom';
import banner1 from '../assets/img/new/design.png';
import banner2 from '../assets/img/new/banner1.png';
import banner3 from '../assets/img/new/banner2.png';


function Homebanner() {
  return (
  <div className="ltn__banner-area mt-120">
  <div className="container">
    <div className="row ltn__custom-gutter--- justify-content-center">
      <div className="col-lg-6 col-md-6">
        <div className="ltn__banner-item">
          <div className="ltn__banner-img">
            <Link to="/shop"><img src={banner1} alt="Banner Image" /></Link>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__banner-item">
              <div className="ltn__banner-img">
                <Link to="/shop"><img src={banner2} alt="Banner Image" /></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="ltn__banner-item">
              <div className="ltn__banner-img">
                <Link to="/shop"><img src={banner3} alt="Banner Image" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  )
}

export default Homebanner