import React from 'react'
import { Link } from 'react-router-dom'
import honeyImage from '../assets/img/new/2-bottles.png'
function Producttab() {
  return (
  <div className="ltn__product-tab-area ltn__product-gutter pt-85 pb-70">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title-area ltn__section-title-2 text-center">
          <h1 className="section-title">Our Products</h1>
        </div>
        <div className="ltn__tab-menu ltn__tab-menu-2 ltn__tab-menu-top-right-- text-uppercase text-center">
          <div className="nav">
            <a className="active show" data-toggle="tab" href="#liton_tab_3_1">Honey</a>
            <a data-toggle="tab" href="#liton_tab_3_2" className>Vegetables</a>
            <a data-toggle="tab" href="#liton_tab_3_3" className>Dried Foods</a>
            
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane fade active show" id="liton_tab_3_1">
            <div className="ltn__product-tab-content-inner">
              <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                {/* ltn__product-item */}
                <div className="col-lg-12">
                  <div className="ltn__product-item ltn__product-item-3 text-center">
                    <div className="product-img">
                      <Link to="/honey"><img style={{height:250, margin:"auto"}} src={honeyImage} alt="#" /></Link>
                      <div className="product-badge">
                        <ul>
                          <li className="sale-badge">-19%</li>
                        </ul>
                      </div>
                      <div className="product-hover-action">
                        <ul>
                       
                          <li>
                            <Link to="/honey" title="Add to Cart" data-toggle="modal" data-target="#add_to_cart_modal">
                              <i className="fas fa-shopping-cart" />
                            </Link>
                          </li>
                       
                        </ul>
                      </div>
                    </div>
                    <div className="product-info">
                      <div className="product-ratting">
                        <ul>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                          <li><a href="#"><i className="fas fa-star" /></a></li>
                        </ul>
                      </div>
                      <h2 className="product-title"><Link to="/honey">Boobee Honey Classic</Link></h2>
                      <div className="product-price">
                        <span>₦1,200.00</span>
                        <del>₦1,500.00</del>
                      </div>
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="liton_tab_3_2">
            <div className="ltn__product-tab-content-inner">
              <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
              



              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="liton_tab_3_3">
            <div className="ltn__product-tab-content-inner">
              <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
            
            
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Producttab