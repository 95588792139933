import React from "react";

function HoneyPageDetails() {
  return (
    <div className="ltn__page-details-area ltn__portfolio-details-area mb-105">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__page-details-inner ltn__portfolio-details-inner">
              <div
                className="ltn__blog-img"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={require("../assets/img/new/design.png")}
                  alt="Image"
                />
              </div>
              <p>
                {" "}
                {/* <span className="ltn__first-letter">B</span> */}
                BooBee Honey is a
                sweet fluid made by honeybees using the nectar of flowering
                plants Sourced mainly from Honey Forest in Naka, Gwer-West Local
                Government Area of Benue State, Nigeria. There are about 320
                different varieties of honey, which vary in color, odor and
                flavor.
              </p>
              <p>
                BooBee Honey contains mostly sugar, as well as a mix of amino
                acids, vitamins, minerals, iron, zinc and antioxidants. In
                addition to its use as a natural sweetener, BooBee honey is used
                as an anti-inflammatory, antioxidant and antibacterial agent.
                People commonly use our honey orally to treat coughs and
                topically to treat burns and promote wound healing.
              </p>
              <p></p>
              <div className="row">
                <div className="col-lg-6">
                  <div className="ltn__testimonial-item ltn__testimonial-item-3">
                    <div className="ltn__testimonial-img">
                      <img
                        src={require("../assets/img/new/banner1.png")}
                        alt="Image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="ltn__testimonial-item ltn__testimonial-item-3">
                    <div className="ltn__testimonial-img">
                      <img
                        src={require("../assets/img/new/banner2.png")}
                        alt="Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="title">
                Applications of BooBee honey for specific conditions includes:
              </h4>
              <p>
                <strong>Cardiovascular disease: </strong>
                Antioxidants in honey might be associated with reduced risk of
                heart disease.
              </p>
              <p>
                <strong>Cough: </strong>
                Studies suggest that eucalyptus honey, citrus honey and labiatae
                honey can act as a reliable cough suppressant for some people
                with upper respiratory infections and acute nighttime cough.
              </p>
              <p>
                <strong>Gastrointestinal disease: </strong>
                Evidence suggests honey might help relieve gastrointestinal
                tract conditions such as diarrhea associated with
                gastroenteritis. Honey might also be effective as part of oral
                rehydration therapy.
              </p>
              <p>
                <strong>Neurological disease: </strong>
                Studies suggest that honey might offer antidepressant,
                anticonvulsant and anti-anxiety benefits. In some studies, honey
                has been shown to help prevent memory disorders.
              </p>
              <p>
                <strong>Wound care: </strong>
                Topical use of medical-grade honey has been shown to promote
                wound healing, particularly in burns.
              </p>

              <div className="row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={require("../assets/img/new/single-bottles2.png")}
                    alt="Image"
                  />
                </div>
              </div>

              <h4 className="title">Application of honey in the kitchen</h4>
              <p>
                <strong>Honey butter: </strong>
                Honey is always a sweet treat, but for a rich topping on bread,
                potatoes or vegetables, we recommend whipping up some honey
                butter at home. It's one of the easiest -- and yummiest --
                things you can make with honey. (If you're from the South, or if
                you've ever eaten soul food, you know what we're talking about.)
                Honey butter is commonly served with starchy foods, like
                cornbread, biscuits, muffins and pancakes, but it's good on
                virtually everything.
              </p>
              <p>
                <strong>Snack Bars: </strong>
                The ooey-gooey quality of honey makes it uniquely suited to a
                particular type of baked good: the soft, chewy snack bar. Honey
                retains its moist, sticky, flexible texture even after baking,
                which means granola bars, trail mix bars and cereal bars are
                prime honey products. It's incredibly easy to make your own,
                healthier alternative to the store-bought kind: You're basically
                just tossing together oats and/or other whole grains, honey, an
                egg, butter or oil and whatever delicious bits of snacking you
                have in the kitchen. The crunch of nuts and seeds and the
                tartness of dried cherries or unsweetened cranberries are
                perfect complements to honey's unique texture and sweetness.
              </p>
              <p>
                <strong> Sauces and spreads: </strong>
                Is there such a thing as a turkey sandwich without honey-mustard
                sauce? Would summer be the same if honey barbecue sauce had
                never been invented? No and no. Salad dressing is where honey
                comes into its own, and sauces are where it thrives. It's no
                secret that honey and pork go well together. Need some proof?
                Try brushing a healthy amount of honey on pork tenderloin before
                putting it in the oven. Honey is also the key ingredient in many
                homemade barbecue sauces. Instead of using store-bought barbecue
                sauce, next time you fire up the grill try stirring a couple
                spoonfuls of honey together with apple cider vinegar,
                Worcestershire sauce, mustard and lemon juice for a tangy and
                sweet sauce. In salad dressing, try adding a few drops of honey
                to vinaigrette for some added sweetness and to help thicken it
                up. (Also, your kids will probably be more likely to ask for
                seconds of salad if there's a touch of honey in the dressing.)
              </p>

              <p>
                <strong>Quick Breads: </strong>
                One way to ensure a moist banana bread? Make sure the recipe
                calls for honey! All sorts of quick breads -- from banana,
                zucchini and corn to beer, herbed and cheesy -- benefit from the
                sweetness and texture of honey. Honey adds a distinct but mild
                flavor to quick-baking, non-yeast breads, which can be
                delightfully easy to prepare (no waiting, no kneading!). Honey
                is sweet but not too-sweet, so it's perfect for the savorier
                flavor profiles, such as the cheddar bread you serve with those
                honey-baked beans, and it helps achieve the moistness that is a
                necessity for any great quick bread. You'll also find the bread
                stays fresher longer, since, as we already know, honey acts as a
                natural preservative.
              </p>

            </div>

            <a href="https://paystack.com/buy/PROD_c3fswgg9rw1z6vc" target="_blank" class="theme-btn-1 btn btn-effect-1" title="Add to Cart" data-toggle="modal" data-target="#add_to_cart_modal">
                                                    <i class="fas fa-shopping-cart"></i>
                                                    <span> BUY NOW FOR </span>
                                                    <del>₦1,500</del>
                                                    <span>  ₦1,200</span>
                                                </a>

          </div>
        </div>
      </div>
    </div>
  );
}

export default HoneyPageDetails;
