import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/new/logo.png";

function MobileMenu() {
  return (
    <div
      id="ltn__utilize-mobile-menu"
      className="ltn__utilize ltn__utilize-mobile-menu"
    >
      <div className="ltn__utilize-menu-inner ltn__scrollbar">
        <div className="ltn__utilize-menu-head">
          <div className="site-logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <button className="ltn__utilize-close">×</button>
        </div>
        <div className="ltn__utilize-menu-search-form">
          <form action="#">
            <input type="text" placeholder="Search..." />
            <button>
              <i className="fas fa-search" />
            </button>
          </form>
        </div>
        <div className="ltn__utilize-menu">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/service">Service</Link>
            </li>
            <li>
              <Link to="/shop">Shop</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
          <ul>
            <li>
              <a href="account.html" title="My Account">
                <span className="utilize-btn-icon">
                  <i className="far fa-user" />
                </span>
                My Account
              </a>
            </li>
            <li>
              <a href="wishlist.html" title="Wishlist">
                <span className="utilize-btn-icon">
                  <i className="far fa-heart" />
                  <sup>3</sup>
                </span>
                Wishlist
              </a>
            </li>
            <li>
              <a href="cart.html" title="Shoping Cart">
                <span className="utilize-btn-icon">
                  <i className="fas fa-shopping-cart" />
                  <sup>5</sup>
                </span>
                Shoping Cart
              </a>
            </li>
          </ul>
        </div>
        <div className="ltn__social-media-2">
          <ul>
            <li>
              <a href="https://www.facebook.com/bogatyfarms" title="Facebook">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/bogatyfarms" title="Twitter">
                <i className="fab fa-twitter" />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/bogatyfarms" title="Instagram">
                <i className="fab fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
